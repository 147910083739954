<template>
  <div>
    <top-info v-model="customer" />

    <b-card
      no-body
      class="custom-border-top-1px table-small-scrollY"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >Lịch sử cập nhật ({{ historyUpdate.length }} lần)
        </b-badge>
      </b-card-header>

      <b-table
        ref="table-suggested-list-product"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        empty-text="Không có lịch sử nào"
        :items="historyUpdate"
        :fields="tableColumnsHistoryUpdate"
      >
        <template #cell(data.price)="data">
          <div class="text-primary text-nowrap">
            <span class="font-weight-bold">
              {{ formatNumber(data.item.data.min_price) }}-{{
                formatNumber(data.item.data.max_price)
              }} <small>{{
                (CURRENCY.find(item => item.value === data.item.data.currency) || {text: 'usd'}).text.toUpperCase()
              }}</small>
            </span>
          </div>
        </template>
        <template #cell(data.status)="data">
          <b-badge
            class="customer-badge"
            :class="{
              ['bg-' + data.item.data.status]: true,
            }"
          >
            {{ (STATUS_TYPE_CUSTOMER.find(item => item.value === data.item.data.status) || {}).text || '' }}
          </b-badge>
        </template>
      </b-table>

    </b-card>

    <form-base v-model="customer" />

    <form-detail v-model="customer" />

    <AttachmentsDetail v-model="customer" />

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >SẢN PHẨM PHÙ HỢP {{ totalSuggestedList ? `(${totalSuggestedList})` : '' }}
        </b-badge>
        <b-button
          variant="primary"
          size="sm"
          :disabled="!isDownloadedPdf || countBase64ImgLoading > countBase64ImgLoaded"
          @click="openModalExportPdf"
        >
          <feather-icon
            v-if="isDownloadedPdf"
            icon="SendIcon"
          />
          <b-spinner
            v-else
            small
          />
          {{ getStatusTextExportPdf }}
        </b-button>
      </b-card-header>

      <b-card-body>

        <b-row class="fit-filter">
          <b-col
            cols="12"
            lg="6"
          >
            <!--           đã xuất-->
            <dynamic-form
              :id="'fit-filter-is_not_exported'"
              :value="fitFilter.is_not_exported"
              :type="'radio'"
              :label="'Trạng thái'"
              :class="'form-group-hor'"
              :options="IS_NOT_EXPORTED"
              @update-value="val => fitFilter.is_not_exported = val ? 1 : ''"
            />
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!--              nhân viên-->
            <dynamic-form
              :id="'fit-filter-user_id'"
              :value="fitFilter.user_id"
              :type="'select'"
              :label="'Nhân viên'"
              text-field="fullname"
              value-field="id"
              :select-default="false"
              :class="'form-group-hor'"
              :options="users"
              @update-value="val => fitFilter.user_id = val"
            />
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!--            Giá-->
            <div class="form-group-hor mb-1">
              <label>Giá (USD)</label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="fitFilter.price_from"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="fitFilter.price_to"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>

          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            lg="6"-->
          <!--          >-->
          <!--            &lt;!&ndash;              nội dung&ndash;&gt;-->
          <!--            <dynamic-form-->
          <!--              :id="'fit-filter-q'"-->
          <!--              :value="fitFilter.q"-->
          <!--              :type="'text'"-->
          <!--              :label="'Nội dung'"-->
          <!--              text-field="fullname"-->
          <!--              value-field="id"-->
          <!--              :class="'form-group-hor'"-->
          <!--              @update-value="val => fitFilter.q = val"-->
          <!--            />-->
          <!--          </b-col>-->
        </b-row>

        <p>
          Sản phẩm được chọn để xuất báo giá: <span class="text-primary">{{ suggestSelectedList.length }}</span>
        </p>
        <b-table
          ref="table-suggested-list-product"
          class="position-relative"
          responsive
          primary-key="id"
          show-empty
          bordered
          small
          empty-text="Không có kết quả nào"
          :items="suggestedList"
          :fields="tableColumnsSuggestedProduct"
        >
          <template #cell(checked)="data">
            <b-form-checkbox
              v-model="suggestSelectedObject[data.item.id]"
            />
          </template>

          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
                :text="data.item.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(company_name)="data">
            <span
              class="d-block font-weight-bold cursor-pointer"
              :class="data.item.close_deal_type === 'close-deal' ? 'text-danger': 'text-primary'"
              @click="handleRowClick(data.item)"
            >
              <template v-if="data.item.close_deal_type === 'close-deal'">[CLOSE DEAL]</template>
              {{ data.item.company_name || data.item.name }}</span>
            <div class="product-badge-wrapper">
              <b-badge
                v-if="data.item.is_not_exported"
                class="product-badge is-exported"
                variant="danger"
              >ĐÃ GỬI
              </b-badge>
            </div>
          </template>

          <template #cell(price)="data">
            <div class="text-primary text-nowrap">
              <span class="font-weight-bold">
                {{ formatNumber(data.item.price) }}
              </span><small>USD / m<sup>2</sup></small>
            </div>
          </template>

          <template #cell(nguoi-dang)="data">
            {{ data.item.user.fullname }}
          </template>
          <template #cell(thoi-han-hop-dong)="data">
            <div class="text-primary text-nowrap">
              {{ data.item.expire_month === 0 ? 'Chưa rõ' : `${!parseTotalMonths(data.item.expire_month).years ? '' : parseTotalMonths(data.item.expire_month).years + ' năm '} ${!parseTotalMonths(data.item.expire_month).months ? '' : parseTotalMonths(data.item.expire_month).months + ' tháng'}` }}
            </div>
          </template>
        </b-table>
      </b-card-body>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSuggestedList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      id="modal-export-pdf"
      ref="modal-export-pdf"
      :title="'Sản phẩm sẽ được xuất báo giá: ' + suggestSelectedList.length"
      header-text-variant="primary"
      ok-only
      ok-title="Xuất báo giá"
      no-stacking
      size="xl"
      @ok="exportPdf"
    >

      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <!--           đã xuất-->
          <dynamic-form
            :id="'modal-export-pdf-lang'"
            :value="$i18n.locale"
            :type="'radio'"
            :label="'Chọn ngôn ngữ'"
            :class="'form-group-hor'"
            :options="[{value: 'en', text: 'English'}, {value: 'vi', text: 'Tiếng Việt'}]"
            @update-value="val => $i18n.locale = val"
          />
        </b-col>
      </b-row>

      <br>

      <div>
        <small>(*) Chỉnh sửa <strong>MÔ TẢ DIỆN TÍCH</strong> chỉ có giá trị xuất File báo giá, sẽ không thay đổi dữ
          liệu của hệ thống</small>
      </div>

      <br>

      <b-table
        ref="table-export-product"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        :items="suggestSelectedList"
        :fields="tableColumnsSuggestedProduct"
      >
        <template #cell(checked)="data">
          <b-form-checkbox
            v-model="suggestSelectedObject[data.item.id]"
          />
        </template>

        <template #cell(image)="data">
          <b-media-aside class="mr-75">
            <b-avatar
              square
              size="64"
              :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
              :text="data.item.image ? '' : 'CL'"
              @img-error="() => showImage160.push(data.item.image)"
            />
          </b-media-aside>
        </template>

        <template #cell(title)="data">
          <span
            class="d-block font-weight-bold cursor-pointer"
            :class="data.item.close_deal_type === 'close-deal' ? 'text-danger': 'text-primary'"
            @click="handleRowClick(data.item)"
          >
            <template v-if="data.item.close_deal_type === 'close-deal'">[CLOSE DEAL]</template>
            {{ data.item.title }}</span>
          <div class="product-badge-wrapper">
            <b-badge
              v-if="data.item.is_not_exported"
              class="product-badge is-exported"
              variant="danger"
            >ĐÃ GỬI
            </b-badge>
          </div>
        </template>

        <template #cell(price)="data">
          <div class="text-primary text-nowrap">
            <span class="font-weight-bold">
              {{
                formatNumber(data.item.input_price)
              }} <small>{{
                (CURRENCY.find(item => item.value === data.item.currency) || {text: 'usd'}).text.toUpperCase()
              }}</small>
            </span>
          </div>
        </template>

        <template #cell(rental_area_description)="data">
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-form-textarea
                :id="'table-export-pdf-rental_area_description' + data.item.id"
                v-model="data.item.rental_area_description"
                placeholder="Mô tả diện tích thuê"
              />
            </b-input-group>
          </b-form-group>
        </template>

        <template #cell(nguoi-dang)="data">
          {{ data.item.user.fullname }}
        </template>
      </b-table>
    </b-modal>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :filename="'ConnectLand báo giá'"
      :paginate-elements-by-height="800"
      :pdf-quality="1"
      pdf-format="a4"
      pdf-orientation="landscape"
      :manual-pagination="true"
      pdf-content-width="1123px"
      @progress="onProgressExportPdf($event)"
      @hasDownloaded="hasDownloadedPdf($event)"
    >
      <section
        slot="pdf-content"
        class="pdf-content"
      >
        <section class="pdf-item">
          <div class="cover-page">
            <div class="top-banner">
              <b-img
                :src="require('@/assets/images/bg-export-excel.png')"
                alt="ConnectLand export pdf cover page"
              />
            </div>
            <div class="top-info">
              <div class="top-left">
                <div class="title pl-2">
                  Thư chào giá văn phòng
                </div>
                <!--              <div class="sub-title">-->
                <!--                office quotation - connectland-->
                <!--              </div>-->
              </div>
              <div class="top-right">
                <div
                  v-if="userData.avatar"
                  class="export-author-avatar"
                >
                  <img
                    :src="getUrlFile(userData.avatar)"
                    :alt="userData.fullname"
                    class="ml-50"
                  >
                </div>
                <div class="export-author-info">
                  <div class="d-flex align-items-end mb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="export-icon"
                      style="color: #2b5c9d"
                      size="24"
                    />
                    <span
                      class="export-info"
                    >{{
                      userData.fullname
                    }}</span>
                  </div>
                  <div class="d-flex align-items-end mb-50">
                    <feather-icon
                      icon="MailIcon"
                      class="export-icon"
                      style="color: #2b5c9d"
                      size="24"
                    />
                    <span
                      class="export-info"
                    >{{
                      userData.email
                    }}</span>
                  </div>
                  <div class="d-flex align-items-end mb-50">
                    <img
                      :src="require('@/assets/images/icons/icon-phone.png')"
                      alt="icon phone"
                      width="24px"
                      class="export-icon"
                    >
                    <span
                      class="export-info"
                    >
                      {{ userData.phone }} ({{ userData.fullname }})
                    </span>
                  </div>
                  <div class="d-flex align-items-end mb-50">
                    <img
                      :src="require('@/assets/images/icons/icon-earth.png')"
                      alt="icon earth"
                      width="24px"
                      class="export-icon"
                    >
                    <span
                      class="export-info"
                    >connectland.vn</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          v-for="loop in Math.ceil(suggestSelectedList.length / MAX_ITEM_IN_EXPORT_TABLE)"
          :key="'loop-table-export-excel-' + loop"
          class="pdf-item"
        >
          <div class="table-product">
            <b-table
              ref="table-export-pdf"
              class="position-relative table-td-p-50 custom-table-style"
              primary-key="id"
              show-empty
              bordered
              head-variant="primary"
              :items="suggestSelectedList.slice((loop-1) * MAX_ITEM_IN_EXPORT_TABLE, loop*MAX_ITEM_IN_EXPORT_TABLE)"
              :fields="tableColumnsExportPdf"
            >
              <template #cell(stt)="data">
                <div class="font-weight-bolder text-center text-primary">
                  {{ (loop - 1) * MAX_ITEM_IN_EXPORT_TABLE + data.index + 1 }}
                </div>
              </template>

              <template #cell(company_name)="data">
                <div class="">
                  {{ data.item.company_name || data.item.name || data.item.title }}
                </div>
              </template>

              <template #cell(rank)="data">
                <div class=" text-uppercase text-center">
                  {{ data.item.class }}
                </div>
              </template>

              <template #cell(address)="data">
                <div style="max-width: 250px">
                  {{ data.item.house_number ? data.item.house_number : '' }}
                  {{ data.item.street_id ? data.item.street.name : '' }},
                  {{ data.item.ward_id ? (data.item.ward.pre + ' ' + data.item.ward.name) : '' }},
                  {{ data.item.district_id ? data.item.district.name : '' }},
                  {{ data.item.province_id ? data.item.province.name : '' }}
                </div>
              </template>

              <template
                #cell(rental_area)="data"
              >
                <div>
                  {{ data.item.rental_area_description }}
                </div>
              </template>

              <template #cell(gia)="data">
                <div class="text-center">
                  <span class="">
                    <strong>{{ formatNumber(data.item.price) }}</strong>
                    <small> USD / m<sup>2</sup></small>
                  </span>
                  <br>
                  <small>{{ data.item.price_description }}</small>
                </div>
              </template>
            </b-table>
          </div>
          <div class="html2pdf__page-break" />

        </section>

        <section
          v-for="(item, idx) in suggestSelectedList"
          :key="'item-' + item.id"
          class="pdf-item"
        >
          <div class="list-product">
            <div
              class="product-item"
            >
              <div>
                <a
                  class="text-primary product-title"
                  :href="item.link"
                >
                  <span class="product-stt">{{ (idx + 1) }}</span>{{ (item.is_rent_all_apartment ? '[NGUYÊN CĂN] Cho thuê ' : '') + item.name }}
                </a>
                <div
                  class="product-content"
                >
                  <div class="product-info-wrapper">

                    <div class="product-info-row">
                      <span>{{ $t('dia-chi') }}</span>
                      <span v-if="$i18n.locale === 'vi'">
                        {{ item.house_number ? item.house_number : '' }}
                        {{ item.street_id ? item.street.name : '' }},
                        {{ item.ward_id ? (item.ward.pre + ' ' + item.ward.name) : '' }},
                        {{ item.district_id ? item.district.name : '' }},
                        {{ item.province_id ? item.province.name : '' }}
                      </span>
                      <span v-else>
                        {{ item.house_number ? item.house_number : '' }}
                        {{ item.street_id ? (removeVietnameseTones(item.street.name) + ' street') : '' }},
                        {{ item.ward_id ? ('ward ' + ' ' + removeVietnameseTones(item.ward.name)) : '' }},
                        {{ item.district_id ? ('district ' + removeVietnameseTones(item.district.name)) : '' }},
                        {{ item.province_id ? removeVietnameseTones(item.province.name) : '' }}
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('hang') }}</span>
                      <span class="text-uppercase">{{ item.class }}</span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('huong') }}</span>
                      <span>{{ (OFFICE_DIRECTION.find(d => d.value === item.direction) || {})[$i18n.locale === 'en' ? 'textEN' : 'text'] }}</span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('ket-cau') }}</span>
                      <span>{{ renderProductOfficeStructure(item, true, false, $i18n.locale) }}</span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('san-dien-hinh') }}</span>
                      <span v-if="item.area_description">{{ item.area_description }}{{ $i18n.locale === 'en' ? 'sqm' : '' }}</span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('dien-tich-thue') }}</span>
                      <span>
                        {{ item.rental_area_description }}
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('gia-thue') }}</span>
                      <span v-if="$i18n.locale === 'vi'">
                        <strong>{{ item.input_price }}</strong>
                        USD/m<sup>2</sup>/{{ $t('thang') }}
                      </span>
                      <span v-else>
                        <strong>${{ item.input_price }}</strong>/sqm/month
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('phi-quan-ly') }}</span>
                      <span v-if="$i18n.locale === 'vi'">
                        <strong>{{ item.service_fee }}</strong>
                        USD/m<sup>2</sup>/{{ $t('thang') }}
                      </span>
                      <span v-else>
                        <strong>${{ item.service_fee }}</strong>/sqm/month
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('thue-vat') }}</span>
                      <span>
                        <strong>{{ $store.state.app.VAT }}</strong>%
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('gia-thue-gop') }}</span>
                      <span class="text-primary">
                        <strong v-if="$i18n.locale === 'vi'">{{ item.price }} USD/m<sup>2</sup>/{{ $t('thang') }}</strong>
                        <strong v-else>${{ item.price }}/sqm/month</strong>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('phi-o-to') }}</span>
                      <span>
                        <template v-if="item.car_parking_fee">
                          <strong>{{ formatNumber(item.car_parking_fee) }}</strong>
                          {{ $t('VNĐ/Xe/Tháng') }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('phi-mo-to') }}</span>
                      <span>
                        <template v-if="item.moto_parking_fee">
                          <strong>{{ formatNumber(item.moto_parking_fee) }}</strong>
                          {{ $t('VNĐ/Xe/Tháng') }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('phi-ngoai-gio') }}</span>
                      <span>
                        <template v-if="item.overtime_fee_type !== 'thoa-thuan'">
                          <strong>{{ formatNumber(item.overtime_fee) }}</strong>
                          {{ $t(item.overtime_fee_type) }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('tien-dien') }}</span>
                      <span>
                        <template v-if="item.electricity_fee && item.electricity_fee_type !== 'phi-nha-nuoc'">
                          <strong>{{ formatNumber(item.electricity_fee) }}</strong> {{ $t(item.overtime_fee_type) }}
                        </template>
                        <template v-else-if="item.electricity_fee">Phí nhà nước</template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('dat-coc') }}</span>
                      <span>
                        <template v-if="item.deposit_time">
                          {{ item.deposit_time }} <small>{{ $t('thang') }}</small>
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('thanh-toan') }}</span>
                      <span>
                        <template v-if="item.pay_time">
                          {{ item.pay_time }} {{ $t('thang') }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                    <div class="product-info-row">
                      <span>{{ $t('thoi-han-thue') }}</span>
                      <span>
                        <template v-if="item.lease_term">
                          {{ item.lease_term }} {{ $t('nawm') }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>
                    <div class="product-info-row">
                      <span>{{ $t('thoi-gian-trang-tri') }}</span>
                      <span>
                        <template v-if="item.decor_time">
                          {{ item.decor_time }} {{ $t('ngay') }}
                        </template>
                        <template v-else>{{ $t('thoa-thuan') }}</template>
                      </span>
                    </div>

                  </div>
                </div>
              </div>

              <div class="product-images">
                <img
                  v-show="getFeaturedImage(item)"
                  :key="'product-content-images-' + refreshKey"
                  :src="getFeaturedImage(item)"
                  class="featured"
                  :alt="item.title"
                >
                <div class="product-content-images">
                  <template v-for="img in getExportProductImage(item)">
                    <img
                      :key="'product-content-image-' + img.name + refreshKey"
                      :src="base64ImgObj[img.origin]"
                      :class="img.origin === item.image ? 'featured' : ''"
                      :alt="img.origin"
                    >
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="html2pdf__page-break" />

        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BTable,
  BBadge,
  BAvatar,
  BMediaAside,
  BRow,
  BCol,
  BPagination,
  BCardBody,
  BButton,
  BFormCheckbox,
  BModal,
  BFormInput,
  BInputGroup,
  BImg,
  BFormGroup, BFormTextarea, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import customerStoreModule from '@/views/customer/customerStoreModule'
import useCustomerOfficeDetail from '@/views/customer-office/detail/useCustomerOfficeDetail'
import FormBase from '@/views/customer-office/detail/FormBase.vue'
import vSelect from 'vue-select'
import FormDetail from '@/views/customer-office/detail/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import AttachmentsDetail from '@/views/components/util/AttachmentsDetail.vue'
import TopInfo from '@/views/customer-office/detail/TopInfo.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import VueHtml2pdf from 'vue-html2pdf'
import useProduct from '@/views/product/useProduct'
import { getUserData } from '@/auth/utils'

const MAX_IMAGE_FOR_ITEM = 1

export default {
  components: {
    BCard,
    BCardHeader,
    BBadge,
    BAvatar,
    FormBase,
    FormDetail,
    Actions,
    AttachmentsDetail,
    BModal,
    BTable,
    BImg,
    TopInfo,
    BMediaAside,
    vSelect,
    BRow,
    BCol,
    BSpinner,
    BPagination,
    BCardBody,
    DynamicForm,
    BButton,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    VueHtml2pdf,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  computed: {
    getStatusTextExportPdf() {
      if (this.countBase64ImgLoaded < this.countBase64ImgLoading) return `Tải ảnh...${this.countBase64ImgLoaded}/${this.countBase64ImgLoading}`
      if (this.isDownloadedPdf) return 'Xuất báo giá'
      return `Xuất PDF... ${this.progressExportPdf}%`
    },
    tableColumnsExportPdf() {
      return [
        {
          label: this.$t('stt'),
          key: 'stt',
          tdClass: 'text-primary position-relative',
        },
        {
          label: this.$t('toa-nha'),
          key: 'company_name',
          tdClass: 'position-relative',
        },
        {
          label: this.$t('hang'),
          key: 'rank',
        },
        {
          label: this.$t('vi-tri'),
          key: 'address',
        },
        {
          label: this.$t('dien-tich-thue'),
          key: 'rental_area',
        },
        {
          label: this.$t('gia-tron-goi'),
          key: 'gia',
        },
      ]
    },
    actionsButton() {
      const validActions = []
      if (ability().can('edit', subject('CustomerOffice', this.customer))) {
        validActions.push(
          this.ACTIONS_BUTTON.EDIT,
          this.customer.is_close_deal ? this.ACTIONS_BUTTON.OPEN : this.ACTIONS_BUTTON.CLOSE,
        )
      }
      return validActions
    },
  },
  created() {
    this.routeParams = this.$route.params
    this.$call(this.fetchCustomer(this.routeParams.id, res => {
      Object.assign(this.customer, res.data)
    }))
    this.$call(this.fetchSuggestedList(this.routeParams.id))
    this.$call(this.fetchHistoryUpdate({
      id: this.routeParams.id,
    }))
    this.$call(store.dispatch('app/fetchUsers'))
  },
  methods: {
    parseTotalMonths(totalMonths) {
      const years = Math.floor(totalMonths / 12)
      const months = totalMonths % 12
      return { years, months }
    },
    onProgressExportPdf(progressNumber) {
      this.progressExportPdf = progressNumber
    },
    hasDownloadedPdf() {
      this.isDownloadedPdf = true
    },
    getFeaturedImage(item) {
      let urlImage = ''
      if (item.image) {
        urlImage = item.image
      } else {
        const imgs = this.getExportProductImage(item) || []
        if (imgs.length) {
          urlImage = imgs[0].origin
        }
      }
      return this.base64ImgObj[urlImage || ''] || ''
    },
    getExportProductImage(product) {
      const files = product.document_files || []
      if (!files.length) return []
      const imgs = files.filter(file => file.mime && file.mime.includes('image') && file.origin !== product.image) || []
      return imgs.slice(0, MAX_IMAGE_FOR_ITEM)
    },
    exportPdf() {
      if (this.suggestSelectedList.length) {
        const dataQuotation = {
          client_id: this.customer.id,
          product_ids: this.suggestSelectedList.map(item => item.id.toString()),
        }
        this.$call(this.quotation(dataQuotation)).then(res => {
          Object.assign(this.customer, res)
          this.progressExportPdf = 0
          this.isDownloadedPdf = false

          // chờ ảnh download tối đa 30s trước khi export
          this.$toastr('Vui lòng chờ xuất dữ liệu thành file PDF... Quá trình có thể mất vài giây!')
          let count = 0
          const waitingImgDownload = setInterval(() => {
            if (this.countBase64ImgLoading <= this.countBase64ImgLoaded
                || count >= 10 * 120) {
              clearInterval(waitingImgDownload)
              this.$refs.html2Pdf.generatePdf()
            }
            count += 1
          }, 100)
        })
      }
    },
    openModalExportPdf() {
      if (!this.suggestSelectedList.length) {
        this.$toastr('Cần chọn tối thiểu 1 sản phẩm', 'warning')
        return
      }
      this.suggestSelectedList.forEach(item => {
        this.getBase64Img(item.image)
        const imgs = this.getExportProductImage(item) || []
        imgs.forEach(imgObj => {
          this.getBase64Img(imgObj.origin)
        })
      })
      this.$refs['modal-export-pdf'].show()
    },
    async getBase64Img(img) {
      if (!img || this.base64ImgObj[img]) return
      this.countBase64ImgLoading += 1
      this.base64ImgObj[img] = await this.getUrlImgBase(img)
      this.countBase64ImgLoaded += 1
      this.refreshKey += 1
    },
    handleRowClick(row) {
      const route = this.$router.resolve({
        name: 'product-office-detail',
        params: { id: row.id },
      })
      window.open(route.href)
    },
    handleActionsButton(btn) {
      if (btn.value === this.ACTIONS_BUTTON.CLOSE.value) {
        this.$call(this.closeDealCustomer({ id: this.customer.id }), true).then(res => {
          Object.assign(this.customer, res)
        })
      }
      if (btn.value === this.ACTIONS_BUTTON.OPEN.value) {
        this.$call(this.openDealCustomer(this.customer.id), true).then(res => {
          Object.assign(this.customer, res)
        })
      }
      if (btn.value === this.ACTIONS_BUTTON.EDIT.value) {
        this.$router.push({ name: 'customer-office-edit', params: { id: this.$route.params.id } })
      }
    },
  },
  setup() {
    const userData = getUserData()
    const MAX_ITEM_IN_EXPORT_TABLE = 7
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    const { renderProductOfficeContent, renderProductOfficeTitle, renderProductOfficeStructure } = useProduct()

    return {
      MAX_ITEM_IN_EXPORT_TABLE,
      userData,
      renderProductOfficeTitle,
      renderProductOfficeStructure,
      renderProductOfficeContent,
      ...useCustomerOfficeDetail(),
    }
  },
}
</script>

<style lang="scss">
.product-badge {
  position: absolute;
  right: .25rem;
  font-size: .7rem !important;

  &.is-exported {
    top: .25rem;
  }
}

.pdf-content {
  background-color: white;

  .pdf-item {
    width: 1123px;
    height: 792px;

    &:not(:first-child) {
      margin-top: 2px;
      padding: 1rem;
      display: flex;
    }

    .cover-page {
      display: flex;
      flex-direction: column;

      .top-banner {
        flex-grow: 1;
        max-height: 594px;
        overflow: hidden;

        img {
          width: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .top-info {
        height: 200px;
        min-height: 200px;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        font-weight: bold;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          border: 32px solid transparent;
          border-left-color: #2b5d9c;
        }

        .top-left {
          flex-grow: 1;
          padding-left: 1rem;
          border: 32px solid #2b5d9c;
          border-right: 42px solid transparent;
          border-left: 32px solid transparent;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 40px;
            color: #2b5d9c;
          }

          .sub-title {
            padding-left: 80px;
            font-size: 20px;
            color: #51bcda;
          }
        }

        .top-right {
          margin-left: -50px;
          display: flex;
          min-width: 450px;
          align-items: center;
          gap: 1rem;
          border-top: 2px solid #2b5d9c;
          border-bottom: 2px solid #2b5d9c;
          border-right: 1px solid #2b5d9c;

          .export-author-avatar {
            img {
              width: 160px;
              height: 160px;
              object-fit: cover;
              border-radius: 50%;
              border: 2px solid #c29a42 !important;
            }
          }

          .export-author-info {
            color: #2b5d9c;

            .export-icon {
              margin-right: .5rem;
            }

            .export-info {
              font-weight: bold;
              line-height: 24px;
            }
          }
        }
      }
    }

    .table-product {
      border: 10px dashed rgba(81, 188, 218, 0.2);
      position: relative;
      padding: 2rem;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 6px solid rgba(81, 188, 218, 0.2);
      }
    }

    .list-product {
      border: 10px dashed rgba(81, 188, 218, 0.2);
      position: relative;
      padding: 2rem;
      width: 100%;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border: 6px solid rgba(81, 188, 218, 0.2);
      }

      .product-item {
        position: relative;
        display: flex;
        height: 100%;

        .product-title {
          display: block;
          margin-bottom: 2rem;
          background: #51bcda33;
          padding: 0.5rem .5rem .5rem 60px;
          position: relative;
          font-weight: bold;
          border-bottom: 1px solid;

          .product-stt {
            position: absolute;
            left: -16px;
            top: 50%;
            transform: translateY(-50%);
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bolder;
            border-radius: 50%;
            border: #2b5d9c;
            background-color: #51bcda;
            color: white;
            font-size: 32px;
          }
        }

        .product-content {
          display: flex;
          justify-content: space-between;
          gap: 2rem;
          color: #252422;
          line-height: 30px;

          .product-info-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .product-info-row {
              border: 1px solid #51bcda33;
              border-bottom: none;
              display: flex;

              &:nth-child(odd) {
                background-color: #51bcda33;
              }

              &:last-child {
                border-bottom: 1px solid #51bcda33;
              }

              > * {
                padding: 0rem .5rem;
              }

              > :nth-child(1) {
                min-width: 200px;
                display: inline-block;
                border-right: 1px solid #51bcda33;
              }
            }
          }
        }

        .product-images {
          min-width: 40%;
          width: 40%;
          display: flex;
          margin-left: .5rem;
          flex-direction: column;

          > * {
            flex-grow: 1;
            height: 50%;
          }

          .featured {
            width: 100%;
            border: 4px solid #51bcda;
            margin-bottom: .5rem;
          }

          .product-content-images {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            img {
              object-fit: cover;
              border: 1px solid gainsboro;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.modal-body {
  height: 80vh;
  overflow-y: auto;
}
</style>
